import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./styles/main.css";
import Homepage from "./pages/Homepage";
import ContactUs from "./pages/ContactusPage";
import AboutPage from "./pages/About";
import ThankyouPage from "./pages/ThankyouPage";
import ErrorPage from "./pages/Errorpage";
import Freedemo from "./components/FreedemoComponent";
import LoginMainPage from "./pages/Loginpage";
import PrivacyPage from "./pages/Privacypolicy";
import ReturnPage from "./pages/ReturnRefund";
import ServicePage from "./pages/servicesPage";
import SignupMainPage from "./pages/Signuppage";
import Termspage from "./pages/TermsConditions";
import MobilePage from "./pages/MobilePage";
import ChoosePlanPage from "./pages/ChoosePlanPage";
import PlansPage from "./pages/PlansPage";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CustomThankYouPage from "./pages/CustomThankYouPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ARScene from "./components/ARScene";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/terms-and-conditions" element={<Termspage />} />
          <Route path="/return-and-refund-policy" element={<ReturnPage />} />
          <Route path="/login" element={<LoginMainPage />} />
          <Route path="/create-account" element={<SignupMainPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/thank-you" element={<ThankyouPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/freedemo" element={<Freedemo />} />
          <Route path="/complete-signup" element={<MobilePage />} />
          <Route path="/choose-plan" element={<ChoosePlanPage />} />
          <Route path="/plans" element={<PlansPage />} />
          <Route path="/payment-successful" element={<CustomThankYouPage />} />
          <Route path="/AR" element={<ARScene />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
