import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/loginpage.css";
import LoginBro from "../assets/Login-bro.png"; // Image from assets
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom"; // Updated import
import googgleicon from "../assets/icons/google.png";
import loader from "../assets/fuzzads-loader.gif"; // Import the loader image

const Loginpage = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false); // State to manage loading
  const navigate = useNavigate(); // Updated usage

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const response = await fetch(
      "https://fuzzads-main-server.onrender.com/api/auth/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: username, password }),
      }
    );

    const data = await response.json();
    setLoading(false); // Stop loading

    if (response.ok) {
      // Store JWT token and initials in localStorage
      localStorage.setItem("token", data.token);
      localStorage.setItem("userInitials", data.initials);

      // Redirect to homepage
      navigate("/"); // Updated usage
    } else {
      alert(data.msg);
    }
  };

  return (
    <div className="fullscreen-container">
      {/* Loader Overlay */}
      {loading && (
        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50">
          <img
            src={loader}
            alt="Loading..."
            style={{ width: "250px", height: "250px" }}
          />
        </div>
      )}

      <div className="container d-flex justify-content-center align-items-center vh-100">
        <div
          className="row w-100 justify-content-center"
          style={{ borderRadius: "20px", overflow: "hidden" }}
        >
          {/* Left Side */}
          <div
            className="col-12 col-md-5 p-3 "
            style={{
              backgroundColor: "#26017b",
              borderTopLeftRadius: "20px",
              borderBottomLeftRadius: "20px",
            }}
          >
            <h2
              className="login-heading text-center fw-bold fs-1"
              style={{ color: "white" }}
            >
              LOGIN
            </h2>
            <p
              className="login-subheading text-center fw-bold fs-4"
              style={{ color: "white" }}
            >
              Ready to be back in Business
            </p>

            <form className="login-form" onSubmit={handleLogin}>
              <div className="mb-3">
                <label
                  htmlFor="username"
                  className="form-label fw-bold"
                  style={{ color: "white" }}
                >
                  Email Address
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  className="form-control"
                  placeholder="Email or Phone No."
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                  style={{ borderRadius: "20px", backgroundColor: "white" }} // Ensure background is white
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="form-label fw-bold"
                  style={{ color: "white" }}
                >
                  Password
                </label>
                <div className="input-group">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="password"
                    name="password"
                    className="form-control"
                    placeholder="Enter your Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    style={{ borderRadius: "20px", backgroundColor: "white" }}
                  />
                  <button
                    type="button"
                    className="input-group-text"
                    onClick={togglePasswordVisibility}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      border: "none",
                      color: "white",
                    }}
                  >
                    {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
              </div>

              <div className="d-flex justify-content-between mb-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="remember"
                  />
                  <label
                    className="form-check-label"
                    style={{ color: "white" }}
                  >
                    Remember me
                  </label>
                </div>
                <div>
                  <a
                    href="/reset-password"
                    className="text-decoration-none"
                    style={{ color: "#00a5e0" }}
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              <button
                type="submit"
                className="btn w-100 fw-bold fs-5"
                style={{
                  backgroundColor: "#00a5e0",
                  color: "white",
                  borderRadius: "20px",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "white";
                  e.currentTarget.style.color = "#00a5e0";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "#00a5e0";
                  e.currentTarget.style.color = "white";
                }}
              >
                LOGIN
              </button>
              {/*<p
                className="or-divider text-center mb-2 mt-2 fw-bold"
                style={{ color: "white" }}
              >
                OR
              </p>

              <button
                type="submit"
                className="btn btn-light w-100 d-flex align-items-center justify-content-center fw-bold"
                style={{ borderRadius: "20px" }}
              >
                <img
                  src={googgleicon}
                  alt="google"
                  className="me-2"
                  style={{ width: "20px", height: "auto" }}
                />{" "}
                Continue with Google
              </button>*/}
              <p
                className="signup-text text-left mt-3 "
                style={{ fontWeight: "bold", color: "white" }}
              >
                Don’t have an FuzzAds Account no worries?{" "}
                <Link
                  className="signup-link"
                  to="/create-account"
                  style={{ textDecoration: "none", color: "#00a5e0" }}
                >
                  Sign Up Now
                </Link>
              </p>
            </form>
          </div>
          {/* Right Side (Hidden on Mobile) */}
          <div
            className="col-12 col-md-5 d-none d-md-flex justify-content-center align-items-center bg-light"
            style={{
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
          >
            <div className="login-content text-center">
              {" "}
              {/* Center the content */}
              <h2 className="company fw-bold" style={{ fontSize: "6rem" }}>
                <span style={{ color: "#26017b" }}>Fuzz</span>
                <span style={{ color: "#00a5e0" }}>Ads</span>
              </h2>
              <img
                src={LoginBro}
                alt="LoginBro"
                className="login-image align-items-end"
                style={{ maxWidth: "100%", height: "auto" }} // Responsive image
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginpage;
