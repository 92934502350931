import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import connect from "../assets/connect.png";
import Popupimg from "../assets/Self-confidence-bro.png";
import { motion } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/connectStyles.css";

const BrandPromotion = ({ scrollToPricing }) => {
  const leftToRightVariant = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const rightToLeftVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const bottomToTopVariant = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const toggleQuotePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleConnectButtonClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  const handleContactUsClick = () => {
    navigate("/contact");
  };

  const handleWhatsAppRedirect = () => {
    const whatsappLink = "https://wa.me/+919310930177";
    window.open(whatsappLink, "_blank");
  };

  const handleSocialRedirect = (e) => {
    const buttonRect = e.currentTarget.getBoundingClientRect();
    const clickPositionX = e.clientX - buttonRect.left;

    if (clickPositionX > buttonRect.width - 30) {
      window.open("https://twitter.com", "_blank");
    } else if (clickPositionX > buttonRect.width - 60) {
      window.open("https://facebook.com", "_blank");
    } else if (clickPositionX > buttonRect.width - 90) {
      window.open("https://instagram.com", "_blank");
    }
  };

  const handleGetStartedClick = () => {
    const token = localStorage.getItem("token");

    if (token) {
      scrollToPricing();
    } else {
      navigate("/create-account");
    }
  };
  return (
    <>
    <div >
      <motion.div
        className="choose-us-container text-center py-5"
        initial="hidden"
        whileInView="visible"
        variants={bottomToTopVariant}
        viewport={{ once: true }}
      >
        <h1 className="fw-bold" style={{ color: "#26017b" }}>
          WHY <span style={{ color: "#00a5e0" }}>CHOOSE US</span> ?
        </h1>
        <h4 style={{ color: "#00a5e0" }}>
          Dedicated to your{" "}
          <span style={{ color: "#26017b" }}>Brand's Success</span>
        </h4>
        <div className="choose-us">
          <div
            className={`sub-choose-us text-center p-4 mx-auto fw-bold rounded-lg`}
            style={{
              backgroundColor: "transparent",
              color: "#26017b",
              maxWidth: "1200px",
              borderRadius: "15px",
              border: "2px solid #26017b",
              textAlign: 'justify'
            }}
          >
            <p>
              At Fuzz<span style={{ color: "#00a5e0" }}>Ads</span>, we don’t
              just provide services—we partner with you to bring your brand’s
              vision to life. Our approach is personal, innovative, and tailored
              to your unique needs. Whether you’re a small business just
              starting out or a company ready to scale, we offer flexible
              solutions that grow with you. What sets us apart is our passion
              for creativity and commitment to delivering results that matter.
            </p>
            <p>
              We believe in transparency, communication, and going the extra
              mile. You’ll always know what we’re working on, and we’ll make
              sure every step of the process aligns with your goals. With fresh
              ideas, a focus on detail, and the latest tools, Fuzz
              <span style={{ color: "#00a5e0" }}>Ads</span> is here to build not
              just your digital presence, but a partnership you can rely on.
            </p>
            <p>
              Choose FuzzAds because your success is our mission, and we’ll make
              sure to take that journey with you every step of the way.
            </p>
            <a
              href="/create-account"
              className="btn"
              style={{
                border: "2px solid #00a5e0",
                backgroundColor: "#00a5e0",
                color: "white",
                borderRadius: "50px",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "white";
                e.currentTarget.style.color = "#00a5e0";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#00a5e0";
                e.currentTarget.style.color = "white";
              }}
            >
              Get Started
            </a>
          </div>
        </div>
      </motion.div>
      </div>
    </>
  );
};

export default BrandPromotion;
