import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/bannerStyles.css";

const Banner = ({ scrollToPricing }) => {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    const token = localStorage.getItem("token");

    if (token) {
      scrollToPricing();
    } else {
      navigate("/login");
    }
  };

  const bottomToTopVariant = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  return (
    <div
    className="container-fluid d-flex justify-content-center align-items-center mt-5 mt-md-0"
    style={{ maxHeight: "400px", backgroundColor: '#26017b' }}
  >
    <motion.div
      className="text-center"
      style={{ fontFamily: "Lato, sans-serif" }}
      initial="hidden"
      whileInView="visible"
      variants={bottomToTopVariant}
      viewport={{ once: true }}
    >
      <p className="text-white mb-4 mt-2 fs-3 fs-md-4" style={{ fontSize: "35px" }}>
        With free Hosting for 2 years + 1 Month <br /> Free Plan{" "}
      </p>
      <p className="text-white mb-4 fs-6 fs-md-5" style={{ marginTop: "-1.5rem" }}>
        "Empowering Brands with Digital Brilliance"
      </p>
      <button
        className="btn get-startedbtn mb-3 fs-5 fs-md-3"
        onClick={handleGetStartedClick}
      >
        Get Started
      </button>
    </motion.div>
  </div>
  
  );
};

export default Banner;
