// src/components/ARScene.js
import React from 'react';

const ARScene = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <a-scene embedded arjs>
        {/* This is the AR Marker */}
        <a-marker preset="hiro">
          {/* AR Content: 3D Object */}
          <a-box position="0 0.5 0" material="color: red;" rotation="0 45 0" scale="0.5 0.5 0.5"></a-box>
        </a-marker>
        
        {/* Camera for AR */}
        <a-camera></a-camera>
      </a-scene>
    </div>
  );
};

export default ARScene;
