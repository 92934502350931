import React from 'react';
import "../styles/main.css";
import Navbar from '../components/Navbar';
import CustomThankYou from '../components/CustomThankYou';

const CustomThankYouPage = () => {
  return (
    <>
    <header>
        <Navbar />
    </header>
    <div class='content-container'>
        <section class='thankyou'>
            <CustomThankYou />
        </section>
    </div>
    </>
  )
}

export default CustomThankYouPage